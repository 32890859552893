import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"

const PageBlog = () => (
  <Layout>
    <PageTitle
      title="Blog"
      description="Keep up to date with the latest news and updates from our team."
    ></PageTitle>
  </Layout>
)

export default PageBlog
